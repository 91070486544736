function path(root, subLink) {
    return `${root}${subLink}`;
}

const rootPath = '/';

export const paths = {
    root: rootPath,
    home: path(rootPath, ''),
    aboutUs: path(rootPath, 'aboutUs'),
    team: path(rootPath, 'aboutUs/team'),
    contactUs: path(rootPath, 'contactUs'),
    service: path(rootPath, 'service'),
    design: path(rootPath, 'service/design'),
    technology: path(rootPath, 'service/technology'),
    webDesign: path(rootPath, 'service/technology/web_design'),
    webDevelopment: path(rootPath, 'service/technology/web_development'),
    backEnd: path(rootPath, 'service/technology/backend'), 
    dotNet: path(rootPath, 'service/technology/dotnet'), 
    ReactDevelopment: path(rootPath, 'service/technology/react_development'), 
    UiDesign: path(rootPath, 'service/technology/ui_design'), 
    UiDevelopment: path(rootPath, 'service/technology/ui_development'), 
    UiGuidelines: path(rootPath, 'service/technology/ui_guidelines'), 
    designSystem: path(rootPath, 'service/technology/design_system'), 
    uxWriting:  path(rootPath, 'service/technology/ux_writing'),
    clients: path(rootPath, 'clients'),
    work: path(rootPath, 'work'),
    blog: path(rootPath, 'blog')
};