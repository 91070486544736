import { Typography, Stack, Grid, Box } from "@mui/material";
import { GetQuote } from "./getQuote";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AnimationIcon from "@mui/icons-material/Animation";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import { Constants } from "../Comman/Constants";

const AboutUs = () => {
  const items = [
    {
      icon: <ZoomInMapIcon style={{ fontSize: 50, color: "#194a89" }} />,
      text: "Within our office environment, ideas, talents, and opportunities converge, creating a dynamic space for growth and achievement.",
    },
    {
      icon: <AnimationIcon style={{ fontSize: 50, color: "#194a89" }} />,
      text: "We serve Synergetic Nexus, every project, every meeting, and every idea exchange is infused with the power of teamwork and cooperation.",
    },
    {
      icon: <HandshakeIcon style={{ fontSize: 50, color: "#194a89" }} />,
      text: "Our Hospitable Workspace is more than just a place to work—it's a community where kindness and support are woven into the fabric of everyday interactions.",
    },
    {
      icon: (
        <FaceRetouchingNaturalIcon style={{ fontSize: 50, color: "#194a89" }} />
      ),
      text: "Our space is a bustling Dynamo of productivity and innovation, where every interaction sparks creativity and drives progress.",
    },
  ];

  return (
    <>
      <Stack ml={{ md: 10, xs: 2 }} mt={{ md: 3, xs: 1 }} alignItems={{md:'start', xs:'center'}}s>
        <Typography
          fontSize={{ md: 30, xs: 25 }}
          color="#0a2c58"
          fontWeight={700}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          ABOUT US
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="start" gap={1}>
          <Typography
            sx={{
              fontSize:{md:15, xs:12},
              fontWeight: 500,
              fontFamily: Constants.fontFamilyJosefinSans,
              color: "#333",
            }}
          >
            Home
          </Typography>
          <Box
            sx={{
              height: "5px",
              width: "5px",
              borderRadius: "50%",
              backgroundColor: "grey",
            }}
          />

          <Typography
            sx={{
              fontSize:{md:15, xs:12},
              fontWeight: 500,
              color: "gray",
              fontFamily: Constants.fontFamilyJosefinSans,
            }}
          >
            About Us
          </Typography>
        </Box>
      </Stack>
      <Stack
        direction={"row"}
        mx={3}
        my={3}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
      >
        <Stack
          width={"45%"}
          ml={4}
          sx={{ position: "relative", display: { md: "inline", xs: "none" } }}
        >
          <img
            src="/images/about2.jpg"
            alt=""
            width={"70%"}
            style={{ position: "relative", zIndex: 1 }}
          />
          <img
            src="/images/about.jpg"
            alt=""
            width={"60%"}
            style={{ position: "absolute", top: 80, left: 170, zIndex: 2 }}
          />
        </Stack>
        <Stack
          width={{ md: "50%", xs: "100%" }}
          gap={2}
          justifyContent={"center"}
        >
          <Typography
            fontSize={{ md: 45, xs: 25 }}
            textAlign={{ xs: "center", md: "start" }}
            fontWeight={600}
            lineHeight={{ md: "60px", xs: "30px" }}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            We are Creative Digital Agency Based in India
          </Typography>
          <Typography
            color="gray"
            lineHeight={1.5}
            fontSize={15}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            Our journey began with a simple yet profound vision: to empower
            individuals and businesses alike through technology. Established
            with a passion for innovation and a commitment to excellence, our
            company has evolved into a trusted partner for clients worldwide.
          </Typography>
          <Typography
            color="gray"
            fontSize={15}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            With a team of dedicated professionals, we strive to deliver
            cutting-edge solutions tailored to meet the unique needs and
            challenges of our clients.
          </Typography>
          <Typography
            color="gray"
            fontSize={15}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            From pioneering startups to established enterprises, we work closely
            with each partner to unlock new opportunities and achieve their
            goals
          </Typography>
        </Stack>
      </Stack>
      <Stack mt={4}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "1.5rem", md: "3.3rem" },
            color: "#000",
            textAlign: "center",
          }}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          Our Environment
        </Typography>
      </Stack>
      {/* <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isMobile ? "center" : "flex-start"}
        mt={10}
        gap={isMobile ? 6 : 0}
      >
        {!isMobile && (
          <Stack
            component={motion.div}
            initial={{ x: "-30%", opacity: 0 }}
            transition={{ duration: 1.2, type: "tween" }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: false, amount: 0.5 }}
            mt={-2}ml={3}
          >
            <img src="/images/about-banner-left.svg" alt="" width={300}  height={280}/>
          </Stack>
        )}

        <Stack
          width={isMobile ? "100%" : "70%"}
          fontSize={{md:43, xs:20}}
          textAlign={{ md: "center", xs: "center" }}
          fontFamily={Constants.fontFamilyJosefinSans}
          fontWeight={600}
        >
          At CodeHawx Studio, we use behavioral science and neuromarketing to
          design, digitize, and expand your company.
        </Stack>

        {!isMobile && (
          <Stack
          // component={motion.div}
          // initial={{ x: "10%", opacity: 0 }}
          // transition={{ duration: 1.2, type: "tween" }}
          // whileInView={{ x: 0, opacity: 1 }}
          // viewport={{ once: false, amount: 0.5 }}
          // justifyContent={"end"}
          // alignItems={"end"}
          mr={3}
          >
            <img src="/images/about-banner-right.svg" alt="" width={300}  height={280} />
          </Stack>
        )}

        {isMobile && (
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Stack
              component={motion.div}
              initial={{ x: "-50%", opacity: 0 }}
              transition={{ duration: 1.2, type: "tween" }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: false, amount: 0.5 }}
            >
              <img src="/images/about-banner-left.svg" alt="" width={150} />
            </Stack>
            <Stack
              component={motion.div}
              initial={{ x: "10%", opacity: 0 }}
              transition={{ duration: 1.2, type: "tween" }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: false, amount: 0.5 }}
            >
              <img src="/images/about-banner-right.svg" alt="" width={150} />
            </Stack>
          </Stack>
        )}
      </Stack> */}
      <Grid
        container
        spacing={4}
        justifyContent="center"
        gap={{ md: 2, xs: 0 }}
        mt={{ md: 2, xs: 3 }}
        mb={{ md: 5, xs: 5 }}
        px={{ xs: 4, md: 0 }}
      >
        {items.map((item, index) => (
          <Grid
            item
            xs={12}
            md={5}
            key={index}
            display="flex"
            alignItems="center"
            gap={4}
          >
            {item.icon}

            <Typography
              variant="body1"
              color="textSecondary"
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              {item.text}
            </Typography>
          </Grid>
        ))}
      </Grid>

      <GetQuote />
    </>
  );
};
export { AboutUs };
