// Import React and the EmailJS library
import {
  Box,
  Button,
  Typography,
  Stack,
  Card,
  TextField,
  Grid,
} from "@mui/material";
import { GetQuote } from "./getQuote";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { Constants } from "../Comman/Constants";

const ContactUs = () => {
  // Use a ref to refer to the form
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7p7lbxu",
        "template_zarq2yc",
        form.current,
        "xbmH34_6os39jU_xh"
      )
      .then(
        (result) => {
          alert("Email sent successfully!");
        },
        (error) => {
          console.log(error.text); // Log any errors
          alert("Failed to send email. Please try again.");
        }
      );
  };

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          py: 4,
          position: "relative",
          backgroundColor: "#F0F8FF",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "rgba(0, 0, 0, 0.05)",
            fontWeight: 900,
            fontSize: { xs: "2.5rem", md: "5rem" },
            zIndex: 0,
          }}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          LETS'S TALK
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            zIndex: 1,
            position: "relative",
            fontSize: { xs: "1.5rem", md: "3.3rem" },
            color: "#000",
            bottom: { md: 40, xs: 20 },
          }}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          Contact Us
        </Typography>
      </Box>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={"#F0F8FF"}
      >
        <Typography
          textAlign={"center"}
          color="grey"
          width={{ md: "30%", sm: "60%", xs: "90%" }}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          Simply fill out the form with your information and we’ll be in touch
          shortly! Thank you!
        </Typography>
      </Stack>
      <Stack bgcolor={"#F0F8FF"}>
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Card sx={{ width: "70%", my: 5, mx: 8, borderRadius: "5px", p: 4 }}>
            <form ref={form} onSubmit={sendEmail}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontFamily={Constants.fontFamilyJosefinSans}>Your Name</Typography>
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="name"
                    variant="standard"
                    sx={{ mt: 1 }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontFamily={Constants.fontFamilyJosefinSans}>Email Address</Typography>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    variant="standard"
                    sx={{ mt: 1 }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontFamily={Constants.fontFamilyJosefinSans}>Phone (Optional)</Typography>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    variant="standard"
                    sx={{ mt: 1 }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" fontFamily={Constants.fontFamilyJosefinSans}>Message</Typography>
                  <TextField
                    fullWidth
                    label="Type Message"
                    name="message"
                    variant="standard"
                    multiline
                    rows={4}
                    sx={{ mt: 1 }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  />
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sx={{ marginTop: "20px" }}
                >
                  <div
                    style={{
                      display: "inline-block",
                      marginBottom: "20px",
                      border: "1px solid lightgray",
                      padding: "10px",
                    }}
                  >
                    I'm not a robot (reCAPTCHA)
                  </div>
                </Grid> */}
                <Grid item xs={12}>
                  <Stack alignItems={"center"} justifyContent={"center"}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: "5px",
                        background: "#0a2c58",
                        "&:hover": {
                          background: "#0a2c58",
                        },
                        width: "auto",
                      }}
                      fontFamily={Constants.fontFamilyJosefinSans}
                    >
                      Send Message
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Stack>
      </Stack>

      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={"#F0F8FF"}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "1.5rem", md: "3.3rem" },
            color: "#000",
            bottom: 40,
            my: 3,
          }}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          Our Location
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          sx={{ p: 2 }}
          width={"90%"}
          flexWrap={"wrap"}
          gap={2}
        >
          <Grid item xs={12} sm={6}>
            <Stack width={{ md: 500, xs: 300, sm: 400 }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28249.2931993935!2d73.87635819427628!3d29.903764166568853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39170c3e4fb8df73%3A0xbebc59c12345678!2sSri%20Ganganagar%2C%20Rajasthan%2C%20India!5e0!3m2!1sen!2sbd!4v1677487840941!5m2!1sen!2sbd"
                width="100%" // Set width to 100% for responsiveness
                height="350"
                style={{ border: 0, borderRadius: "8px" }}
                allowFullScreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                title="company"
                referrerPolicy="no-referrer"
              ></iframe>
            </Stack>
          </Grid>

          <Stack spacing={2} justifyContent={"center"}>
            <Stack direction="row" spacing={1} alignItems="center">
              <PhoneIcon sx={{ color: "#0a2c58" }} />
              <Typography variant="body1" fontFamily={Constants.fontFamilyJosefinSans}>+91-7742776888</Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <EmailIcon sx={{ color: "#0a2c58" }} />
              <Typography variant="body1" fontFamily={Constants.fontFamilyJosefinSans}>info@codehawx.com</Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <LocationOnIcon sx={{ color: "#0a2c58" }} />
              <Stack>
                <Typography variant="body1" fontFamily={Constants.fontFamilyJosefinSans}>
                  #584 B, Ashok Nagar, SSB Road, Sri GangaNagar,
                </Typography>

                <Typography variant="body1" fontFamily={Constants.fontFamilyJosefinSans}>
                  Rajasthan, 335001, India
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <GetQuote />
    </>
  );
};
export { ContactUs };
