// TestimonialCard.tsx
import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { Constants } from "../Comman/Constants";

const TestimonialCard = ({ quote, author, designation }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: "20px",
        maxWidth: "600px",
        margin: "auto",
      }}
    >
      <Divider
        sx={{
          width: "150px",
          height: "4px",
          bgcolor: "primary.main",
          marginBottom: "20px",
        }}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "10rem",
            color: "#d1e7dd",
            fontWeight: "bold",
            mr: 1,
          }}
        >
          &rdquo;
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { md: "1.6rem", xs: "1rem" },
            color: "#333",
            fontFamily: Constants.fontFamilyJosefinSans,
            lineHeight: { md: '40px', xs: '25px' }
          }}
        >
          {quote}
        </Typography>
      </Box>

      <Box width={"100%"} textAlign={"right"}>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", fontSize: "1rem" }}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          {author}
        </Typography>
        <Typography variant="caption" sx={{ color: "#666" }} fontFamily={Constants.fontFamilyJosefinSans}>
          {designation}
        </Typography>
      </Box>
    </Box>
  );
};

export { TestimonialCard };