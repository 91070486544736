import { Box, Typography, Stack, Grid } from "@mui/material";
import { GetQuote } from "./getQuote";
import { TestimonialCard } from "../components/testimonial-card";
import { expertise } from "../mock/mock-data";
import { Constants } from "../Comman/Constants";

const Clients = () => {
  return (
    <>
      <Box
        sx={{
          alignItems: "start",
          flexWrap: "wrap",
        }}
      >
        <Stack ml={{ md: 10, xs: 2 }} mt={{ md: 3, xs: 1 }} alignItems={{md:'start', xs:'center'}}>
          <Typography
            fontSize={{ md: 30, xs: 25 }}
            color="#0a2c58"
            fontWeight={700}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            CLIENTS
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            gap={1} 
          >
            <Typography
              sx={{
                fontSize:{md:15, xs:12},
                fontWeight: 500,
                fontFamily:Constants.fontFamilyJosefinSans,
                color:'#333',
              }}
            >
              Home
            </Typography>

            <Box
              sx={{
                height: "5px",
                width: "5px",
                borderRadius: "50%",
                backgroundColor: "grey",
              }}
            />

            <Typography
              sx={{
                fontSize:{md:15, xs:12},
                fontWeight: 500,
                color: "grey",
                fontFamily:Constants.fontFamilyJosefinSans
              }}
            >
              Clients
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"center"}
          alignItems={"center"}
          //   mb={5}
          mt={{ md: 3, xs: 0 }}
        >
          <Stack width={{ md: "65%", xs: "90%" }} sx={{ zIndex: 2 }}>
            <Typography
              fontSize={{ md: 35, xs: 30 }}
              sx={{
                color: "#333",
                fontWeight: "bold",
              }}
              mt={{ xs: 5, md: 0 }}
              fontFamily={Constants.fontFamilyJosefinSans}
              textAlign={"center"}
            >
              We create satisfied customers by fusing technology with business
              and creativity.
            </Typography>
          </Stack>
          <Stack
            width={{ md: "70%", xs: "80%" }}
            sx={{ zIndex: -1, position: "relative", bottom: 80 }}
          >
            <object
              data="/images/client.svg"
              type="image/svg+xml"
              width="100%"
              height="auto"
            >
              Your browser does not support SVG.
            </object>
          </Stack>
        </Stack>
      </Box>
      <Stack mb={5}>
        <Stack
          fontSize={{ md: 50, xs: 30 }}
          color={"#194a89"}
          fontWeight={600}
          alignItems={"center"}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          TESTIMONIALS
        </Stack>
        <Stack
          fontSize={{ md: 35, xs: 20 }}
          color={"black"}
          alignItems={"center"}
          mt={3}
          fontFamily={Constants.fontFamilyJosefinSans}
          textAlign={"center"}
        >
          50+ successful projects and content clients
        </Stack>
        <Stack>
          <div>
            <TestimonialCard
              quote="They always had the expertise necessary to accomplish tasks, and they stayed within budget."
              author="Anonymous"
              designation="Application Support Consultant, University"
            />
          </div>
        </Stack>
      </Stack>
      <Stack>
        <Stack
          fontSize={{ md: 50, xs: 30 }}
          color={"#194a89"}
          fontWeight={600}
          alignItems={"center"}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          EXPERTISE
        </Stack>
        <Stack
          fontSize={{ md: 30, xs: 20 }}
          color={"black"}
          alignItems={"center"}
          mt={3}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          Domains we have worked for.
        </Stack>
        <Stack mx={5} my={2}>
          <Grid container spacing={0} justifyContent="center" sx={{ mt: 4 }}>
            {expertise.map((skill, index) => {
              return (
                <Grid item xs={6} sm={4} md={2} key={index}>
                  <Box
                    sx={{
                      py: { xs: "5px", md: "10px" },
                      textAlign: "center",
                      fontSize: { md: "1.5rem", xs: "1rem" },
                      transition: "color 0.8s ease",
                    }}
                  >
                    <img src={skill.img} alt="" />
                  </Box>
                  <Box
                    sx={{
                      py: { xs: "5px", md: "10px" },
                      p: { xs: 2, md: 3.5 },
                      textAlign: "center",
                      fontSize: { md: "1.5rem", xs: "1rem" },
                      transition: "color 0.8s ease",
                      fontFamily: Constants.fontFamilyJosefinSans,
                    }}
                  >
                    {skill.title}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>

      <GetQuote />
    </>
  );
};
export { Clients };
