import { Stack, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { questions } from "../mock/mock-data";
import { Constants } from "../Comman/Constants";

const Solution = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [openIndex, setOpenIndex] = useState(0);
  const navigate = useNavigate();

  const toggleContent = (index) => {
    setOpenIndex(openIndex === index ? index : index);
  };

  const handleShowBlogDetails = (id) => {
    navigate(paths.blog, { state: { id } });
  };

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      justifyContent="space-between"
      alignItems={isMobile ? "center" : "flex-start"}
      mt={10}
      mx={4}
      gap={isMobile ? 6 : 2}
    >
      {!isMobile && (
        <Stack
          component={motion.div}
          initial={{ x: "-50%", opacity: 0 }}
          transition={{ duration: 1.2, type: "tween" }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: false, amount: 0.5 }}
        >
          <img src="/images/faq-left-girl.svg" alt="" width={"100%"} />
        </Stack>
      )}
      <Stack gap={8} width={isMobile ? "100%" : "60%"}>
        {questions.map((item, index) => (
          <Stack direction={"row"} gap={2} key={index}>
            <Stack width={50}>
              <Stack
                width={50}
                borderRadius={"50%"}
                height={50}
                bgcolor={openIndex === index ? "#194a89" : "#444"}
                justifyContent={"center"}
                alignItems={"center"}
                onClick={() => toggleContent(index)}
                sx={{ cursor: "pointer", "&:hover": { background: "#194a89" } }}
              >
                <img
                  src={`/images/${openIndex === index ? "cross.svg" : "plus.svg"}`}
                  alt=""
                  width={28}
                  style={{ color: "#fff" }}
                />
              </Stack>
            </Stack>
            <Stack ml={2}>
              <Stack
                color={openIndex === index ? "#194a89" : "#444"}
                fontSize={{ md: 27, xs: 20 }}
                sx={{ fontFamily: "sans-serif", fontWeight: 500 }}
                fontFamily={Constants.fontFamilyJosefinSans}
              >
                {item.question}
              </Stack>
              {openIndex === index && (
                <>
                  <Stack
                    color="#444444"
                    mt={4}
                    sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      wordSpacing: 3,
                      fontFamily: "sans-serif",
                    }}
                    fontSize={{ md: 18, xs: 15 }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    {item.answer}
                  </Stack>
                  <Stack
                    color="#e76e6e"
                    mt={2}
                    sx={{
                      textDecoration: "underline",
                      wordSpacing: 3,
                      fontFamily: "sans-serif",
                      "&:hover": { cursor: "pointer" }
                    }}
                    fontSize={{ md: 14, xs: 15 }}
                    onClick={() => handleShowBlogDetails(item.id)}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    Read more
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
      {!isMobile && (
        <Stack
          component={motion.div}
          initial={{ x: "10%", opacity: 0 }}
          transition={{ duration: 1.2, type: "tween" }}
          whileInView={{ x: 0, opacity: 1 }}
          viewport={{ once: false, amount: 0.5 }}
        >
          <img src="/images/faq-right-girl.svg" alt="" width={"100%"} />
        </Stack>
      )}

      {isMobile && (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack
            component={motion.div}
            initial={{ x: "-50%", opacity: 0 }}
            transition={{ duration: 1.2, type: "tween" }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: false, amount: 0.5 }}
          >
            <img src="/images/faq-left-girl.svg" alt="" width={"100%"} />
          </Stack>
          <Stack
            component={motion.div}
            initial={{ x: "10%", opacity: 0 }}
            transition={{ duration: 1.2, type: "tween" }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: false, amount: 0.5 }}
          >
            <img src="/images/faq-right-girl.svg" alt="" width={"100%"} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export { Solution };
