import { Box, Typography, Stack } from "@mui/material";
import { GetQuote } from "./getQuote";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { questions } from "../mock/mock-data";
import { Constants } from "../Comman/Constants";

const Blog = () => {
  const location = useLocation();
  const [content, setContent] = useState(null);

  useEffect(() => {
    const questionId = location.state?.id;
    console.log("questionId", questionId);

    if (questionId) {
      const selectedQuestion = questions.find((item) => item.id === questionId);
      setContent(selectedQuestion);
    }
  }, [location.state]);

  const typographyStyles = {
    color: "#333",
    mt: 2,
    ml: { xs: 3, md: 3 },
    mr: { xs: 3, md: 3 },
    fontSize: { md: 20, xs: 12 },
    fontFamily: Constants.fontFamilyJosefinSans,
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "start",
          flexWrap: "wrap",
        }}
        width={{ md: "100%", xs: "90%" }}
      >
        <Stack ml={{ md: 10, xs: 2 }} mt={{ md: 3, xs: 0 }}>
          <Typography
            fontSize={{ md: 30, xs: 30 }}
            color="#0a2c58"
            fontWeight={700}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            BLOG
          </Typography>
          <Typography fontSize={{ md: 18, xs: 14 }} fontFamily={Constants.fontFamilyJosefinSans}>Home . Blog</Typography>
        </Stack>
        <Stack
          // spacing={{md:4,}}
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Stack
            width={{ md: "50%", xs: "90%" }}
            justifyContent={"center"}
            alignItems={"center"}
            ml={2}
          >
            <Typography
              fontSize={{ md: 45, xs: 28 }}
              sx={{
                color: "#333",
                fontWeight: "bold",
              }}
              mt={{ xs: 5, md: 0 }}
              ml={2}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              {content?.question}
            </Typography>
          </Stack>
          <Stack width={{ md: "32%", xs: "85%" }} ml={4}>
            <img
              src={content?.img}
              alt=""
              width={"100%"}
              style={{ position: "relative", zIndex: 1 }}
            />
          </Stack>
        </Stack>
      </Box>
      {content?.id === 1 && (
        <Stack direction={"row"} mx={{ md: 4, xs: 2 }} flexWrap={"wrap"} mb={10}>
          <Stack width={{ md: "70%", xs: "100%" }}>
            <Typography
              sx={{ ...typographyStyles, mt: { l: 10, xs: 5, md: 10 } }}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              Innovation is at the heart of technology, and in the business
              world, innovation is all about finding new ways to do things in
              order to give clients better services, solutions, and products.
              Modern technology is{" "}
            </Typography>

            <Typography sx={typographyStyles}>
              Innovation is at the heart of technology, and in the business
              world, innovation is all about finding new ways to do things in
              order to give clients better services, solutions, and products.
            </Typography>

            <Typography sx={typographyStyles}>
              Technology is not only necessary for routine company operations,
              but when used wisely, it can also spur corporate expansion and
              success. Instead of seeing technology as a tool to automate tasks,
              successful companies see it as a tool to create new avenues for
              business.
            </Typography>

            <Typography sx={typographyStyles}>
              We look at a few of the numerous ways that technology may help
              businesses expand in this article.
            </Typography>

            <Typography mx={2} fontSize={19} fontWeight={600} my={2}>
              Making good use of digital technology in marketing:
            </Typography>

            <Typography sx={typographyStyles}>
              These days, having a good online presence is crucial for
              businesses to succeed as well as sometimes just to survive. It is
              believed that many SMEs fail within the first five years of their
              existence, with half of them failing due to a lack of a strong
              digital presence. SMEs must use digital technology wisely to get
              the most out of their limited marketing expenditures.
            </Typography>

            <Typography sx={typographyStyles}>
              Creating a well-defined digital marketing plan that outlines your
              objectives, approaches, and performance evaluation methods is
              crucial. While many businesses are engaged on the internet, many
              lack a clear plan. This may result in the wastage of resources and
              the passing up of chances.
            </Typography>

            <Typography sx={typographyStyles}>
              Of course, the great majority of businesses will need a strong
              website. This goes beyond simply having a nice design; a
              successful website should also be optimized for search engines,
              mobile devices, and users. In particular, the latter needs
              constant attention.{" "}
            </Typography>
            <Typography sx={typographyStyles}>
              In addition to having a strong website, marketing strategies like
              pay-per-click advertising, email marketing, and social media
              marketing may be quite successful. Digital technologies have the
              potential to expedite corporate growth by expanding a company's
              consumer base and fostering enduring interactions that foster
              brand loyalty.{" "}
            </Typography>

            <Typography mx={2} fontSize={19} fontWeight={600} my={2}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              Using productivity tools to enhance customer service and cut
              expenses:
            </Typography>

            <Typography sx={typographyStyles}>
              Business growth is influenced by a wide range of factors. These
              include maximizing income, cutting expenses for operations, and
              offering excellent customer service.
            </Typography>

            <Typography sx={typographyStyles}>
              Businesses may accomplish all of these goals with the use of
              technology. Raising productivity and efficiency is essential for
              cutting expenses and maximizing profits, which can subsequently be
              used to expand the company.
            </Typography>

            <Typography sx={typographyStyles}>
              Productivity software is made to assist companies in cutting
              expenses, replacing tedious paper-based procedures, and increasing
              operational efficiency. The office productivity, accounting,
              email, and communications software packages are the most widely
              used productivity software products.
            </Typography>
            <Typography sx={typographyStyles}>
              The growth of cloud and mobile technologies in recent years has
              resulted in a significant expansion of the area of corporate
              productivity software.
            </Typography>
            <Typography sx={typographyStyles}>
              Customer acquisition and retention are critical to the development
              of any business. This means that companies have to maintain a high
              standard of customer service at all times. Technology is also
              helpful in this regard. Professional Services Automation (PSA) and
              customer relationship management (CRM) technologies of today are
              becoming increasingly useful for organizations and their clients.
            </Typography>
            <Typography sx={typographyStyles}>
              These days, it's all about integration. The newest PSA and CRM
              systems can easily link with corporate communications systems to
              deliver insights that support first contact resolution and raise
              customer service standards.
            </Typography>

            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              Making use of mobile technology:
            </Typography>

            <Typography sx={typographyStyles}>
              The growing number of individuals working outside of the typical
              office setting may be attributed to both the expansion of flexible
              working rights in the UK and the developments in mobile technology
            </Typography>

            <Typography sx={typographyStyles}>
              Businesses that invest in mobile technology can encourage a
              contented workforce where employees have a better work-life
              balance. This is often seen as advantageous for businesses, as
              contented employees result in higher output and lower expenses due
              to higher employee retention. Everyone has won.
            </Typography>
            <Typography sx={typographyStyles}>
              Technology-enabled worker mobilization can also assist a company
              in achieving the crucial cost savings required to maximize
              earnings and promote expansion. Employees may work remotely, which
              reduces the need for more physical space and the operating
              expenses of maintaining larger office buildings.
            </Typography>
            <Typography sx={typographyStyles}>
              Businesses should think about cloud solutions that enable
              customers to access the same apps and services from numerous
              devices and locations in addition to investing in mobile devices,
              in order to fully utilize mobile technology. They will also make
              it possible for workers to collaborate effectively, allowing them
              to function as a cohesive unit even when they are not in the same
              location.
            </Typography>
          </Stack>
          <Stack
            width={{ md: "30%", xs: "100%" }}
            // justifyContent={"center"}
            // alignItems={"center"}
            mt={7}
          >
            <img
              src="/images/Teachnology-inside.svg"
              width="100%"
              height="auto"
              alt="Teachnology-inside.svg"
            />
          </Stack>
        </Stack>
      )}
      {content?.id === 2 && (
        <Stack direction={"row"} mx={{ md: 4, xs: 2 }} flexWrap={"wrap"} mb={10}>
          <Stack width={{ md: "70%", xs: "100%" }}>
            <Typography
              sx={{ ...typographyStyles, mt: { l: 10, xs: 5, md: 10 } }}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              Predictions are consistently unreliable, that much is
              certain.Despite several attempts, it is nearly impossible to fully
              foretell the future. Change is the one constant in retail sales.{" "}
            </Typography>
            <Typography sx={typographyStyles}>
              That implies double for the e-commerce sector. Trends that used to
              take years to follow through in the days of brick and mortar
              stores are now followed in months due to the industry's rapid
              innovation.
            </Typography>
            <Typography sx={typographyStyles}>
              With 23% of all sales predicted to occur online by 2025, online
              shopping is expanding quickly. This expansion promotes creativity.
            </Typography>
            <Typography sx={typographyStyles}>
              What will affect e-commerce in the future will be covered here.
            </Typography>
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              1. AI is becoming more and more influential
            </Typography>
            <Typography sx={typographyStyles}>
              Artificial intelligence and machine learning will keep being more
              and more incorporated into the e-commerce user experience, from
              copywriting to chatbots to customization.
            </Typography>
            <Typography sx={typographyStyles}>
              ChatGPT will facilitate the creation of sales copy, AI will become
              widely used through browser integrations (such as those with
              Bing), and products like Google Bard will improve customer
              support.
            </Typography>
            <Typography sx={typographyStyles}>
              Even while AI is still in its infancy, it has the potential to
              drastically alter the way e-commerce experiences are delivered.
            </Typography>
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              2. Success in omnichannel e-commerce is important
            </Typography>
            <Typography sx={typographyStyles}>
              Take a look at your phone and consider all the applications you
              use frequently. What number do you usually utilize in a day? What
              number of alerts are you receiving?
            </Typography>
            <Typography sx={typographyStyles}>
              This is e-commerce and omnichannel marketing in the future. Having
              a few different avenues to contact clients is insufficient; you
              also need many, dependable channels that offer direct
              communications.
            </Typography>
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              3. Flexibility and scalability are what will keep companies
              successful
            </Typography>
            <Typography sx={typographyStyles}>
              For growing businesses, the capacity to scale up is essential, but
              it's equally critical to have the flexibility to scale down when
              necessary to save expenses. An effective business may minimize
              resources during sluggish periods and respond swiftly to spikes in
              traffic.
            </Typography>
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              4. The use of mobile is still expanding
            </Typography>
            <Typography sx={typographyStyles}>
              Mobile devices generate more than 55% of all internet traffic, and
              the trend of mobile shopping is only continuing to grow. 5G
              adoption will improve mobile commerce and digital experiences,
              allowing e-commerce platforms to offer more interesting content.
            </Typography>{" "}
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              5. The experience of the customer is crucial
            </Typography>
            <Typography sx={typographyStyles}>
              A significant portion of your brand is the customer experience,
              and sales are driven by your brand. Technological developments
              have made it possible for e-commerce businesses to go closer to
              providing an in-person experience online. Those that excel at it
              stand to get more clients and income.
            </Typography>
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              5. The experience of the customer is crucial
            </Typography>
            <Typography sx={typographyStyles}>
              A significant portion of your brand is the customer experience,
              and sales are driven by your brand. Technological developments
              have made it possible for e-commerce businesses to go closer to
              providing an in-person experience online. Those that excel at it
              stand to get more clients and income.
            </Typography>
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              6. Chatbots
            </Typography>
            <Typography sx={typographyStyles}>
              A well-integrated chatbot may help your clients self-service,
              handle numerous problems on their own, and even act as a kind of
              round-the-clock help desk. It also reduces the workload for your
              customer care representatives. They may improve customer
              satisfaction by making it simpler to locate information pertaining
              to customer care.
            </Typography>
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              7. Modern site search technology
            </Typography>
            <Typography sx={typographyStyles}>
              AI has the ability to permanently alter the way that consumers
              look for products, yet search functionalities are always changing.
              A comprehensive on-site search makes it easier for customers to
              find things. This implies spending more time checking out and less
              time looking.
            </Typography>
          </Stack>
          <Stack
            width={{ md: "30%", xs: "100%" }}
            // justifyContent={"center"}
            // alignItems={"center"}
            mt={7}
          >
            <img
              src="/images/Teachnology-inside.svg"
              width="100%"
              height="auto"
              alt="Teachnology-inside.svg"
            />
          </Stack>
        </Stack>
      )}
      {content?.id === 3 && (
        <Stack direction={"row"} mx={{ md: 4, xs: 2 }} flexWrap={"wrap"} mb={10}>
          <Stack width={{ md: "70%", xs: "100%" }}>
            <Typography
              sx={{ ...typographyStyles, mt: { l: 10, xs: 5, md: 10 } }}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              In recent times, investors, businesses, and the general public
              have been more interested in artificial intelligence, augmented
              reality, and virtual reality.{" "}
            </Typography>

            <Typography sx={typographyStyles}>
              There's no denying that AI, AR, and VR have already significantly
              altered the way products are sold online.
            </Typography>

            <Typography sx={typographyStyles}>
              I'm going to talk about how, In the previous several years,
              E-Commerce has evolved in light of AI, AR, and VR today.
            </Typography>

            <Typography sx={typographyStyles}>
              The usage of artificial intelligence (AI), augmented reality (AR),
              and virtual reality (VR) technology in the e-commerce sector has
              increased recently. With the ability to offer a customized and
              engaging purchasing experience, these technologies have completely
              changed the way e-commerce businesses communicate with their
              clientele. Let's examine the applications of AI, AR, and VR in
              e-commerce in more detail.
            </Typography>

            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              1. Chatbots using AI and Customized Suggestions
            </Typography>

            <Typography sx={typographyStyles}>
              E-Commerce businesses utilize AI-powered chatbots to help
              customers around-the-clock without requiring human participation.
              These chatbots comprehend consumer inquiries and provide tailored
              responses by utilizing Natural Language Processing (NLP).
              Furthermore, AI systems use consumer data analysis to offer
              tailored product suggestions and promotions, improving the
              relevance and convenience of the shopping experience.
            </Typography>

            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              2. Virtual Try-Ons with Augmented Reality:
            </Typography>

            <Typography sx={typographyStyles}>
              AR technology makes shopping more interesting and immersive by
              enabling shoppers to virtually try on things before making a
              purchase. Fashion and beauty shops are using virtual try-on
              technology to enable customers to see how things would appear on
              them without having to physically visit the store.
            </Typography>

            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              3. Using Virtual Reality to Provide Immersive Product Showcases{" "}
            </Typography>

            <Typography sx={typographyStyles}>
              eCommerce organizations are utilizing virtual reality (VR)
              technology to produce immersive product presentations that give
              customers a more engaging way to explore things. Virtual reality
              (VR) may be used to create interactive product tours that provide
              buyers a 360-degree perspective of items and their features,
              facilitating more informed purchase decisions.
            </Typography>
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              4. Inventory Management Assisted by AI
            </Typography>
            <Typography sx={typographyStyles}>
              In order to minimize the risk of overstocking or stockouts, AI
              systems may evaluate consumer purchase data to forecast product
              demand and manage inventory levels. This enables e-commerce
              businesses to deliver goods more quickly and with less waste,
              which raises customer happiness and lowers operating expenses.
            </Typography>
            <Typography mx={2} fontSize={19} fontWeight={600} my={2} fontFamily={Constants.fontFamilyJosefinSans}>
              5. Individualized Costing
            </Typography>

            <Typography sx={typographyStyles}>
              AI systems are capable of analyzing consumer data and forecasting
              a customer's price tolerance for a given item or service. This
              enables e-commerce businesses to provide customized pricing,
              guaranteeing that clients believe they are receiving a good deal
              and promoting recurring business.
            </Typography>
            <Typography sx={typographyStyles}>
              The way online businesses engage with customers is being
              revolutionized by the integration of AI, AR, and VR in e-commerce,
              offering a more immersive and customized purchasing experience.
            </Typography>
            <Typography sx={typographyStyles}>
              E-Commerce businesses are benefiting from these technologies by
              being able to give immersive product demos, tailored suggestions,
              and inventory level optimization, all of which boost consumer
              pleasure and loyalty. We may anticipate seeing even more
              cutting-edge applications of AI, AR, and VR in e-commerce as
              technology develops.
            </Typography>
          </Stack>
          <Stack
            width={{ md: "30%", xs: "100%" }}
            // justifyContent={"center"}
            // alignItems={"center"}
            mt={7}
          >
            <img
              src="/images/Teachnology-inside.svg"
              width="100%"
              height="auto"
              alt="Teachnology-inside.svg"
            />
          </Stack>
        </Stack>
      )}

      <GetQuote />
    </>
  );
};
export { Blog };
