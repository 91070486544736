import React from 'react';
import { Constants } from "../Comman/Constants";

const TeamCard = ({ title, image, description, linkedIn, tags }) => {
    return (
        <div
            style={{
                backgroundColor: '#fff',
                borderRadius: '8px',
                padding: '30px',
                textAlign: 'center',
                //boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s',
                width: 250,
                height: 225,
                marginLeft: '15px'
            }}

        ><img
                src={image}
                alt={title}
                style={{
                    width: '70%',
                    height: 170,
                    borderRadius: '8px',
                    // marginTop: '10px',
                }}
            />
            <a href={linkedIn} target="_blank" rel="noopener noreferrer">
                <img
                    src='/images/linkedin.svg'
                    alt='linkedin'
                    style={{
                        width: '10%',
                        height: '10%',
                        borderRadius: '4px',
                    }}
                />
            </a>
            <h3
                style={{
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    color: 'black',
                    margin: '0',
                    fontFamily: Constants.fontFamilyJosefinSans
                }}
            >
                {title}
            </h3>

            <p
                style={{
                    fontSize: '1rem',
                    color: '#4a4a4a',
                    margin: '5px 0',
                    fontFamily: Constants.fontFamilyJosefinSans
                }}
            >
                {description}
            </p>

            {/* <div style={{ marginTop: '5px' }}>
                {tags.map((tag, index) => (
                    <span
                        key={index}
                        style={{
                            fontSize: '1rem',
                            color: '#555',
                            marginRight: '10px',
                        }}
                    >
                        {tag}
                    </span>
                ))}
            </div> */}
        </div>
    );
};

export { TeamCard };