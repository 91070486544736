import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { paths } from "../routes/paths";
import { Constants } from "../Comman/Constants";

const Footer = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();

  const handleShowAboutUs = () => {
    navigate(paths.aboutUs);
  };
  const handleShowContactUs = () => {
    navigate(paths.contactUs);
  };
  const handleShowClients = () => {
    navigate(paths.clients);
  };
  const handleShowWebDesign = () => {
    navigate(paths.webDesign);
  };
  const handleShowWebDevelopment = () => {
    navigate(paths.webDevelopment);
  };
  const handleShowBackEnd = () => {
    navigate(paths.backEnd);
  };
  const handleShowDotNet = () => {
    navigate(paths.dotNet);
  };
  const handleShowReact = () => {
    navigate(paths.ReactDevelopment);
  };
  const handleShowUIDesign = () => {
    navigate(paths.UiDesign);
  };
  const handleShowUIDevelopment = () => {
    navigate(paths.UiDevelopment);
  };
  const handleShowUIGuidelines = () => {
    navigate(paths.UiGuidelines);
  };
  const handleShowDesignSystem = () => {
    navigate(paths.designSystem);
  };
  const handleShowUXWriting = () => {
    navigate(paths.uxWriting);
  };
  const handleShowTeam = () => {
    navigate(paths.team);
  };

  return (
    <>
      <Stack my={6}>
        <Stack
          direction={"row"}
          width={"100%"}
          justifyContent="space-around"
          flexWrap={"wrap"}
          sx={{ margin: "0 auto" }}
        >
          <Stack>

            <Stack
              width={{ md: "25%", xs: "50%" }}
              color={"#194a89"}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              direction={"row"}
            >
              <Typography
                fontFamily={Constants.fontFamilyJosefinSans}
                fontSize={30}
                fontWeight={"bold"}
                color="#1d4b7d"
              >
                Code
              </Typography>
              <Typography
                fontFamily={Constants.fontFamilyJosefinSans}
                fontSize={30}
                fontWeight={"bold"}
                color="#80c2e8"
              >
                Hawx
              </Typography>
            </Stack>
            <Typography fontFamily={Constants.fontFamilyJosefinSans}>Sri Ganganagar</Typography>
            <Typography fontFamily={Constants.fontFamilyJosefinSans}>info@codehawx.com</Typography>
            <Typography fontFamily={Constants.fontFamilyJosefinSans}>+91-7742776888</Typography>
            <Typography fontFamily={Constants.fontFamilyJosefinSans}>#584 B, Ashok Nagar, Sri Ganganagar,</Typography>
            <Typography fontFamily={Constants.fontFamilyJosefinSans}>Rajasthan, 335001, India</Typography>
          </Stack>

          {isSmallScreen ? (
            <Stack sx={{ width: "90%", gap: 2, mt: 2 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" fontWeight={"bold"} fontFamily={Constants.fontFamilyJosefinSans}>
                    Technology Services
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    onClick={handleShowWebDesign}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    Web Design
                  </Typography>
                  <Typography
                    onClick={handleShowWebDevelopment}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    Web Development
                  </Typography>
                  <Typography
                    onClick={handleShowBackEnd}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    BackEnd
                  </Typography>
                  <Typography
                    onClick={handleShowDotNet}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    .Net
                  </Typography>
                  <Typography
                    onClick={handleShowReact}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    React
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" fontWeight={"bold"} fontFamily={Constants.fontFamilyJosefinSans}>
                    Design Service
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    onClick={handleShowUIDesign}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    UI Design
                  </Typography>
                  <Typography
                    onClick={handleShowUIDevelopment}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    UI Development
                  </Typography>
                  <Typography
                    onClick={handleShowUIGuidelines}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    UI Guidelines
                  </Typography>
                  <Typography
                    onClick={handleShowDesignSystem}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    Design System
                  </Typography>
                  <Typography
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                    onClick={handleShowUXWriting}
                  >
                    UX Writing
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" fontWeight={"bold"} fontFamily={Constants.fontFamilyJosefinSans}>
                    Quick Links
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    onClick={handleShowClients}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    Clients
                  </Typography>
                  <Typography
                    onClick={handleShowAboutUs}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    About Us
                  </Typography>
                  <Typography
                    onClick={handleShowTeam}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    Team
                  </Typography>
                  <Typography
                    onClick={handleShowContactUs}
                    sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                    fontFamily={Constants.fontFamilyJosefinSans}
                  >
                    Contact US
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Stack>
          ) : (
            <>
              <Stack>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: Constants.fontFamilyJosefinSans, fontWeight: "bold" }}
                >
                  Technology Services
                </Typography>
                <Typography
                  onClick={handleShowWebDesign}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  Web Design
                </Typography>
                <Typography
                  onClick={handleShowWebDevelopment}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  Web Development
                </Typography>
                <Typography
                  onClick={handleShowBackEnd}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  BackEnd
                </Typography>
                {/* <Typography sx={{ "&:hover": { cursor: 'pointer' , color: "#1d4b7d"} }}>FrontEnd</Typography> */}
                <Typography
                  onClick={handleShowDotNet}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  .Net
                </Typography>
                <Typography
                  onClick={handleShowReact}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  React
                </Typography>
              </Stack>

              <Stack>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: Constants.fontFamilyJosefinSans, fontWeight: "bold" }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  Design Service
                </Typography>
                <Typography
                  onClick={handleShowUIDesign}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  UI Design
                </Typography>
                <Typography
                  onClick={handleShowUIDevelopment}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  UI Development
                </Typography>
                <Typography
                  onClick={handleShowUIGuidelines}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  UI Guidelines
                </Typography>
                <Typography
                  onClick={handleShowDesignSystem}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  Design System
                </Typography>
                <Typography
                  onClick={handleShowUXWriting}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  UX Writing
                </Typography>
              </Stack>
              <Stack mr={5}>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: Constants.fontFamilyJosefinSans, fontWeight: "bold" }}
                >
                  Quick Links
                </Typography>
                <Typography
                  onClick={handleShowClients}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  Clients
                </Typography>
                <Typography
                  onClick={handleShowAboutUs}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  About Us
                </Typography>
                <Typography
                  onClick={handleShowTeam}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  Team
                </Typography>
                <Typography
                  onClick={handleShowContactUs}
                  sx={{ "&:hover": { cursor: "pointer", color: "#1d4b7d", textDecoration: 'underline' } }}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  Contact US
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>

      <Stack
        bgcolor={"#194a89"}
        height={60}
        width={"100%"}
        justifyContent={"space-between"}
        direction={"row"}
      >
        <Stack
          color={"white"}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"row"}
          ml={{ md: 5, xs: 1 }}
        >
          <Stack direction={"row"} gap={1}>
            <Stack
              bgcolor={"white"}
              height={40}
              width={40}
              borderRadius={"50%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img
                src="/images/facebook.svg"
                alt="Logo"
                width={30}
                height={30}
              />
            </Stack>
            <Stack
              bgcolor={"white"}
              height={40}
              width={40}
              borderRadius={"50%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img
                src="/images/linkedin.svg"
                alt="Logo"
                width={30}
                height={30}
              />
            </Stack>

            <Stack
              bgcolor={"white"}
              height={40}
              width={40}
              borderRadius={"50%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img
                src="/images/instagram.svg"
                alt="Logo"
                width={30}
                height={30}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack justifyContent={"center"} mr={{ md: 4, xs: 1 }}>
          <Typography color={"white"} fontSize={{ md: 13, xs: 10 }} fontFamily={Constants.fontFamilyJosefinSans}>
            @codehawx.com
          </Typography>
          <Typography color={"white"} fontSize={{ md: 13, xs: 10 }} fontFamily={Constants.fontFamilyJosefinSans}>
            All rights reserved 2024
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export { Footer };
