import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import Card from "./sticky-card";
import { Constants } from "../Comman/Constants";

const CustomCard = () => {
  const technologies = [
    { name: "React JS", image: "/images/reactNative.png" },
    { name: "Next JS", image: "/images/next.png" },
    { name: "React Native", image: "/images/reactNative.png" },
    { name: "WordPress", image: "/images/WordPress_blue_logo.svg" },
    { name: "WebFlow", image: "/images/webflow-icon.png" },
    { name: ".Net", image: "/images/dotNet.png" },
    { name: "My SQL", image: "/images/mySql.png" },
    { name: "SQL Server", image: "/images/microsoft-sql-server-logo.svg" },
    { name: "Postgre", image: "/images/postGre.png" },
    { name: "Azure", image: "/images/azure.png" },
    { name: "AWS", image: "/images/aws.png" },
    { name: "GCP", image: "/images/gcp.png" },
  ];

  return (
    <>
      <Stack width="90vw" justifyContent={"center"} alignItems={"center"}>
        <Card
          color="#dee2e6"
          sx={{
            borderRadius: 50,
            BorderTop: "10px white solid",
            width: "100%",
            // height: "100vh",
            display: "flex",
          }}
        >
          <Stack width={"100%"}>
            <Stack direction={"row"} gap={2} mt={2} ml={{ md: 6, xs: 1 }}>
              <Stack
                width={18}
                height={18}
                bgcolor={"#e9ecef"}
                borderRadius={20}
                mt={1}
              ></Stack>
              <Stack
                width={18}
                height={18}
                bgcolor={"#e9ecef"}
                borderRadius={20}
                mt={1}
              ></Stack>
              <Stack
                width={18}
                height={18}
                bgcolor={"#e9ecef"}
                borderRadius={20}
                mt={1}
              ></Stack>
              <Stack
                width={"70%"}
                height={32}
                bgcolor={"#e9ecef"}
                borderRadius={20}
                justifyContent={"center"}
              >
                <Typography ml={3} color="black" fontFamily={Constants.fontFamilyJosefinSans}>
                  Technology
                </Typography>
              </Stack>
              <Stack
                width={18}
                height={18}
                bgcolor={"#e9ecef"}
                borderRadius={20}
                mt={1}
              ></Stack>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"} gap={2}>
              <Stack width={{ md: "60%", xs: "100%" }}>
                <Stack
                  fontSize={{ md: 60, xs: 20 }}
                  color={"white"}
                  mt={2}
                  ml={3}
                  fontWeight={600}
                  fontFamily={Constants.fontFamilyJosefinSans}
                >
                  Technology
                </Stack>

                {/* Technology Cards Section */}
                <Grid container spacing={4} my={2}>
                  {technologies.map((tech, index) => (
                    <Grid item xs={6} sm={4} md={4} key={index} width={"auto"}>
                      <Stack alignItems="center" gap={1}>
                        <img
                          src={tech.image}
                          alt=""
                          width={60}
                          height={60}
                          style={{ objectFit: "contain" }}
                        />
                        <Typography
                          variant="body1"
                          fontWeight={600}
                          color="black"
                          fontFamily={Constants.fontFamilyJosefinSans}
                        >
                          {tech.name}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
              <Stack
                component={motion.div}
                initial={{ x: "-50%", opacity: 0 }}
                transition={{
                  duration: 1.2,
                  type: "tween",
                }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: false, amount: 0.5 }}
                sx={{ display: { xs: "none", md: "inline" } }}
              >
                <object
                  data="/images/technology.svg"
                  type="image/svg+xml"
                  height={"100%"}
                  width={"100%"}
                >
                  Your browser does not support SVG.
                </object>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </>
  );
};

export default CustomCard;
