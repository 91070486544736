import { Stack, Typography, Box, Grid } from "@mui/material";
import { GetQuote } from "../getQuote";
import React from "react";
import { Constants } from "../../Comman/Constants";

const UiDevelopment = () => {
  const skills = [
    ["UI Development", "UI Design", "Animations"],
    ["UI Guidelines", "Design System", "UX Design"],
    ["User Research", "User Consultancy", "UX Writing"],
  ];

  const steps = [
    {
      icon: "/images/empathise.svg",
      title: "Empathise",
      description:
        "As a successful IT firm, we apply the tried-and-true human-focused design approach that is motivated by empathy to create the greatest digital solutions that correspond to user preferences.",
    },
    {
      icon: "/images/define.svg", // Replace with actual icon
      title: "Define (the Problem)",
      description:
        "To handle complicated problems with the fewest possible obstacles, appropriate logic and details are accurately merged with aspects like design functions, features, functionalities, workflow, and more.",
    },
    {
      icon: "/images/ideation.svg", // Replace with actual icon
      title: "Ideation",
      description:
        "We develop roadmaps while outlining your company's journey in order to decipher human-focused issue statements using tried-and-true ideation approaches and a client-centric mindset.",
    },
    {
      icon: "/images/prototype.svg", // Replace with actual icon
      title: "Prototype",
      description:
        "Based on a variety of trials, we create a prototype that is results-driven and designed to create a real digital connection with the highest degree of quality and detail.",
    },
    {
      icon: "/images/testing.svg", // Replace with actual icon
      title: "Testing",
      description:
        "A clear large picture of design experience benchmarks, blueprints, personas, and journey maps of ideal customers are imparted by evaluating the whole product usability at Omni-channel platforms as we refine our designs utilizing cutting-edge testing approaches.",
    },
  ];

  return (
    <>
      <Box
        sx={{
          alignItems: "start",
          flexWrap: "wrap",
        }}
      >
        <Stack ml={{ md: 10, xs: 2 }} mt={{ md: 3, xs: 1 }} alignItems={{md:'start', xs:'center'}}>
          <Typography
            fontSize={{ md: 30, xs: 25 }}
            color="#194a89"
            fontWeight={700}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            UI DEVELOPMENT
          </Typography>
          <Box
              display="flex"
              alignItems="center"
              justifyContent="start"
              gap={1}
            >
              <Typography
                sx={{
                  fontSize:{md:15, xs:12},
                  fontWeight: 500,
                  fontFamily: Constants.fontFamilyJosefinSans,
                  color: "#333",
                }}
              >
                Home
              </Typography>

              <Box
                sx={{
                  height: "5px",
                  width: "5px",
                  borderRadius: "50%",
                  backgroundColor: "grey",
                }}
              />

              <Typography
                sx={{
                  fontSize:{md:15, xs:12},
                  fontWeight: 500,
                  color: "#333",
                  fontFamily: Constants.fontFamilyJosefinSans,
                }}
              >
                Service
              </Typography>
              <Box
                sx={{
                  height: "5px",
                  width: "5px",
                  borderRadius: "50%",
                  backgroundColor: "grey",
                }}
              />

              <Typography
                sx={{
                  fontSize:{md:15, xs:12},
                  fontWeight: 500,
                  color: "#333",
                  fontFamily: Constants.fontFamilyJosefinSans,
                }}
              >
                Design
              </Typography>
              <Box
                sx={{
                  height: "5px",
                  width: "5px",
                  borderRadius: "50%",
                  backgroundColor: "grey",
                }}
              />

              <Typography
                sx={{
                  fontSize:{md:15, xs:12},
                  fontWeight: 500,
                  color: "gray",
                  fontFamily: Constants.fontFamilyJosefinSans,
                }}
              >
                UI Development
              </Typography>
            </Box>
        </Stack>
        <Stack
          // spacing={{md:4,}}
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Stack
            width={{ md: "40%", xs: "90%" }}
            justifyContent={"center"}
            alignItems={"center"}
            ml={2}
          >
            <Typography
              fontSize={{ md: 40, xs: 30 }}
              sx={{
                color: "#333",
                fontWeight: "bold",
              }}
              mt={{ xs: 5, md: 0 }}
            >
              A UI developer's job is to use front-end technologies to transform
              original software design thoughts and ideas into reality{" "}
            </Typography>
          </Stack>
          <Stack
            width={{ md: "40%", xs: "100%" }}
            mr={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src="/images/Design-UI-Development.png"
              width="100%"
              height="auto"
              alt="Design-UI-Development.png"
            />
          </Stack>
        </Stack>
      </Box>

      <Stack
        direction={{ md: "row", xs: "column", sm: "row" }}
        alignItems="center"
        spacing={{ md: 2, xs: 2 }}
        sx={{ padding: 4, backgroundColor: "white" }}
        mx={3}
      >
        <Box
          sx={{
            backgroundColor: "#1e4c7e",
            color: "#ffffff",
            padding: 4,
            width: { md: "30%", xs: "100%", sm: "60%" },
            height: { md: "300px", xs: "auto", sm: "300px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" component="h2" fontWeight="bold" fontFamily={Constants.fontFamilyJosefinSans}>
            What is UI Development?
          </Typography>
        </Box>

        <Box sx={{ width: { md: "70%", xs: "100%" } }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { md: "22px", xs: "18px" },
              lineHeight: 1.6,
              color: "#333333",
              marginLeft: { md: "10%", xs: 0 },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            They are able to translate the user interface design solution into
            engineering software by understanding both its practical meaning and
            artistic vision. A UI developer serves as the link between the
            presentation layer and the back-end layer. He or she comprehends
            both and fills a specific role as the translational layer there,
            ensuring that none suffers from the other's shortcomings.
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: { md: 8, xs: 5 },
          border: "1px solid #cccccc",
          backgroundColor: "#ffffff",
          position: "relative",
          my: 5,
          mx: { md: 7, xs: 4 },
        }}
      >
        <Typography
          sx={{
            color: "#333333",
            fontWeight: "400",
            fontSize: { md: 30, xs: 22 },
          }}
        >
          To Know More Read{" "}
          <Typography
            component="span"
            sx={{
              color: "#1e4c7e",
              fontWeight: "700",
              "&:hover": { cursor: "pointer", color: "#14406a" },
              fontSize: { md: 40, xs: 25 },
            }}
            onClick={() => console.log("Navigate to Blog")}
          >
            Our Blog
          </Typography>
        </Typography>

        <svg
          width="200"
          height="50"
          viewBox="0 0 200 50"
          style={{
            position: "absolute",
            bottom: "45px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <path
            d="M10 30 C 70 50, 130 10, 190 30"
            stroke="#60c660"
            strokeWidth="3"
            fill="transparent"
          />
          <polygon
            points="185,24 195,30 185,36"
            fill="#60c660"
            transform="rotate(10 190,30)"
          />
        </svg>
      </Box>

      <Box
        sx={{
          backgroundColor: "#1a4d8f",
          color: "white",
          textAlign: "center",
          padding: "50px 20px",
        }}
      >
        <Stack spacing={2} py={5}>
          <Typography variant="h4" component="h2" fontWeight="bold" fontFamily={Constants.fontFamilyJosefinSans}>
            What Do We Serve ?
          </Typography>
          <Typography
            variant="h4"
            component="p"
            sx={{ fontFamily: Constants.fontFamilyJosefinSans }}
          >
            We help you translate a simple idea into an exotic Digital design
            transformation vision.{" "}
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ padding: "50px 20px", textAlign: "center" }}>
        <Typography variant="h4" gutterBottom fontWeight="bold" fontFamily={Constants.fontFamilyJosefinSans}>
          Web Development
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              width: "100px",
              height: "3px",
              backgroundColor: "black",
            }}
          />
        </Box>

        <Grid container spacing={0} justifyContent="center">
          {skills.flat().map((skill, index) => {
            const isLastInRow = (index + 1) % 3 === 0;
            const isLastRow = index >= skills.flat().length - 3;

            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box
                  sx={{
                    py: { xs: "5px", md: "10px" },
                    p: { xs: 2, md: 3.5 },
                    textAlign: "center",
                    fontSize: { md: "2rem", xs: "1.5rem" },
                    borderRight: {
                      md:
                        isLastInRow && window.innerWidth >= 600
                          ? "none"
                          : "1px solid black",
                      xs: "none",
                    },
                    borderBottom:
                      isLastRow && window.innerWidth >= 600
                        ? "none"
                        : "1px solid black",
                    transition: "color 0.8s ease",
                    "&:hover": {
                      border: "1px solid #1a4d8f",
                      color: "white",
                      cursor: "pointer",
                      background: "#1a4d8f",
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  {skill}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Stack my={5}>
        <Stack alignItems={"center"}>
          <Typography
            fontSize={{ md: 50, xs: 40 }}
            fontWeight={600}
            textAlign={"center"}
          >
            The Way We Work?
          </Typography>
          <Typography fontSize={{ md: 25, xs: 10 }} fontFamily={Constants.fontFamilyJosefinSans}>
            Leaving no stone unturned at every step.
          </Typography>
        </Stack>
        <Stack mx={{ md: 10, xs: 3 }} mt={4}>
          <Grid container spacing={10}>
            {steps.map((step, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Stack spacing={2}>
                  {/* <Box fontSize={48}>{step.icon}</Box> */}
                  <img
                    src={step.icon}
                    alt=""
                    width={100}
                    height={100}
                    style={{ objectFit: "contain" }}
                  />
                  <Typography fontSize={25} fontWeight={600} component="div" fontFamily={Constants.fontFamilyJosefinSans}>
                    {step.title}
                  </Typography>
                  <Typography variant="body1" fontFamily={Constants.fontFamilyJosefinSans}>{step.description}</Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
      <GetQuote />
    </>
  );
};
export { UiDevelopment };
