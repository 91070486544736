import React from "react";

const Card = ({ children, color, sx }) => {
  const cardStyle = {
    // position: "sticky",
    // top: 0,
    width: "80%",
    display: "flex",
    justifyContent: "center",
    fontSize: "2rem",
    color: "white",
    backgroundColor: color,
    marginBottom: "1rem",
    ...sx,
  };

  return <div style={cardStyle}>{children}</div>;
};

export default Card;