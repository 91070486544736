export const expertise = [
  { title: "Automobiles", img: "/images/automobiles.svg" },
  { title: "Capital", img: "/images/capital.svg" },
  { title: "Consulting", img: "/images/consulting.svg" },
  { title: "E-Commerce", img: "/images/e-commerce.svg" },
  { title: "Education", img: "/images/education.svg" },
  { title: "Energy", img: "/images/energy.svg" },
  { title: "Events", img: "/images/events.svg" },
  { title: "Food", img: "/images/food.svg" },
  { title: "Hardware", img: "/images/hardware.svg" },
  { title: "Healthcare", img: "/images/healthcare.svg" },
  { title: "Hospitality", img: "/images/hospitality.svg" },
  { title: "Industries", img: "/images/industries.svg" },
  { title: "Movies", img: "/images/movies.svg" },
  { title: "Publishing", img: "/images/publishing.svg" },
  { title: "Real-Estate", img: "/images/Real-Estate.svg" },
  { title: "SAAS", img: "/images/ship.svg" },
  { title: "Ship", img: "/images/ship.svg" },
  { title: "Travel", img: "/images/travel.svg" },
];
export const questions = [
  {
    id: 1,
    img: "/images/how-technology-can-assist-in-business-growth.png",
    question: "How technology can assist in business growth?",
    answer:
      "Innovation is at the heart of technology, and in the business world, innovation is all about finding new ways to do t...",
  },
  {
    id: 2,
    img: "/images/blog2.png",
    question: "How can automation improve efficiency?",
    answer:
      "Predictions are consistently unreliable, that much is certain.Despite several attempts, it is nearly impossible to fully...",
  },
  {
    id: 3,
    img: "/images/blog3.png",
    question: "What role does data play in decision making?",
    answer:
      "In recent times, investors, businesses, and the general public have been more interested in artificial intelligence, ...",
  },
];