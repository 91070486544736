import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Stack,
  Typography,
  Button,
  Divider,
  Fade,
  Drawer,
  IconButton,
  Box,
} from "@mui/material";
import { Contact } from "../modules/contact";
import { AboutCards } from "../modules/aboutCards"
import MenuIcon from "@mui/icons-material/Menu"; // Importing menu icon
import useMediaQuery from "@mui/material/useMediaQuery"; // To detect screen size
import { paths } from "../routes/paths";
import CloseIcon from "@mui/icons-material/Close";
import { Constants } from "../Comman/Constants";

const NavBar = () => {
  const [showContact, setShowContact] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hoveringService, setHoveringService] = useState(false);
  const [hoveringAbout, setHoveringAbout] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const location = useLocation();

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#service-menu") &&
        !event.target.closest("#service-hover")
      ) {
        setShowContact(false);
      }
      if (
        !event.target.closest("#about-menu") &&
        !event.target.closest("#about-hover")
      ) {
        setShowAbout(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavHover = () => {
    setShowContact(false);
    setShowAbout(false);
  };
  const handleShowHome = () => {
    window.location.href = "/";
  };
  const handleShowAboutUs = () => {
    navigate(paths.aboutUs);
  };
  const handleShowContactUs = () => {
    navigate(paths.contactUs);
  };
  const handleShowWork = () => {
    navigate(paths.work);
  };
  const handleShowClients = () => {
    navigate(paths.clients);
  };
  const handleShowTechnology = () => {
    navigate(paths.technology);
  };
  const handleShowDesign = () => {
    navigate(paths.design);
  };
  const handleShowTeam = () => {
    navigate(paths.team);
  };
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        mt={0}
        height={60}
      >
        <Stack
          width={{ md: "25%", xs: "50%" }}
          color={"#194a89"}
          fontSize={30}
          ml={{ md: 5, xs: 2 }}
          // mt={1}
          onClick={handleShowHome}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          direction={"row"}
        >
          <Typography
            fontFamily={Constants.fontFamilyJosefinSans}
            fontSize={40}
            fontWeight={"bold"}
            color="#1d4b7d"
          >
            Code
          </Typography>
          <Typography
            fontFamily={Constants.fontFamilyJosefinSans}
            fontSize={40}
            fontWeight={"bold"}
            color="#80c2e8"
          >
            Hawx
          </Typography>
        </Stack>

        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon sx={{ width: 35, height: 35, color: "" }} />
            </IconButton>

            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Stack
                direction="column"
                gap={2}
                sx={{
                  padding: 2,
                  width: "90vw",
                  backgroundColor: "#f0f2f5",
                }}
              >
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack
                    onClick={handleShowHome}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    direction={"row"}
                    background={"red"}
                  >
                    <Typography
                      fontFamily={"Arial, sans-serif"}
                      fontSize={30}
                      fontWeight={"bold"}
                      color="#1d4b7d"
                    >
                      Code
                    </Typography>
                    <Typography
                      fontFamily={"Arial, sans-serif"}
                      fontSize={30}
                      fontWeight={"bold"}
                      color="#80c2e8"
                    >
                      Hawx
                    </Typography>
                  </Stack>
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseIcon />
                  </IconButton>
                </Stack>

                {[
                  {
                    label: "WORK",
                    onClick: handleShowWork,
                    icon: "images/nav-design-boy-2 (1).svg",
                  },
                  {
                    label: "TECHNOLOGY",
                    onClick: handleShowTechnology,
                    icon: "/images/nav-design-boy-1.svg",
                  },
                  {
                    label: "DESIGN",
                    onClick: handleShowDesign,
                    icon: "/images/nav-design-girl.svg",
                  },
                  {
                    label: "CLIENTS",
                    onClick: handleShowClients,
                    icon: "/images/navClients.svg",
                  },
                  {
                    label: "ABOUT US",
                    onClick: handleShowAboutUs,
                    icon: "images/nav-design-about (1).svg",
                  },
                  {
                    label: "TEAM",
                    onClick: handleShowTeam,
                    icon: "/images/nav-design-team.svg",
                  },
                ].map((item, index) => (
                  <Box
                    key={index}
                    onClick={item.onClick}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: 1,
                      pl: 3,
                      borderRadius: 2,
                      // background: "linear-gradient(to top,  #D8E1E8 50%, #ffffff 50%)",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      marginBottom: 1,
                      cursor: "pointer",
                      border: " 1px solid #8fafde",
                    }}
                  >
                    <Typography variant="body1" fontSize={20} fontWeight={500}>
                      {item.label}
                    </Typography>
                    <Box
                      component="img"
                      src={item.icon}
                      alt={item.label}
                      sx={{ width: 50, height: 50 }}
                    />
                  </Box>
                ))}

                <Button
                  variant="contained"
                  sx={{
                    background: "#194a89",
                    borderRadius: "8px",
                    marginTop: 2,
                    fontWeight: "bold",
                    padding: 2,
                  }}
                  onClick={handleShowContactUs}
                >
                  Contact
                </Button>
              </Stack>
            </Drawer>
          </>
        ) : (
          <Stack
            direction={"row"}
            gap={{ md: 8, xs: 5 }}
            width={{ md: "60%", xs: "65%" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              sx={{
                fontFamily: "inherit",
                borderBottom:
                  location.pathname === "/" ? "2px solid #194a89" : "none",
                color: location.pathname === "/" ? "#194a89" : "none",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onMouseEnter={handleNavHover}
              onClick={handleShowHome}
            >
              Home
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "inherit",
                "&:hover": {
                  cursor: "pointer",
                },
                borderBottom:
                  location.pathname === paths.work ? "2px solid #194a89" : "none",
                color: location.pathname === paths.work ? "#194a89" : "none",
              }}
              onMouseEnter={handleNavHover}
              onClick={handleShowWork}
            >
              Work
            </Typography>
            <Typography
              id="service-hover"
              sx={{
                borderBottom: location.pathname.includes(paths.service)
                  ? "2px solid #194a89"
                  : "none",
                color: location.pathname.includes(paths.service)
                  ? "#194a89"
                  : "inherit",
                "&:hover": {
                  cursor: "pointer",
                  "&::after": {
                    height: "40px",
                    top: "-90%",
                    transition: "height 0.3s ease, top 0.3s ease",
                  },
                  "&::before": {
                    top: "-25%",
                    transition: "top 0.3s ease",
                  },
                },
                position: "relative",
                fontFamily: "inherit",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  height: "30px",
                  width: "1px",
                  backgroundColor: "#194a89",
                  top: "-100%",
                  right: "-30%",
                  transform: "translateX(-50%)",
                },
                "&::before": {
                  content: '""',
                  position: "absolute",
                  height: "7px",
                  width: "7px",
                  backgroundColor: "#194a89",
                  borderRadius: "50%",
                  top: "-55%",
                  right: "-43.5%",
                  transform: "translate(-50%, 20px)",
                },
              }}
              onMouseEnter={() => {
                handleNavHover();
                setShowContact(true);
              }}
              onMouseLeave={() => setHoveringService(false)}
              variant="body2"
            >
              Service
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "inherit",
                "&:hover": {
                  cursor: "pointer",
                },
                borderBottom:
                  location.pathname === paths.clients
                    ? "2px solid #194a89"
                    : "none",
                color: location.pathname === paths.clients ? "#194a89" : "none",
              }}
              onMouseEnter={handleNavHover}
              onClick={handleShowClients}
            >
              Clients
            </Typography>
            <Typography
              id="about-hover"
              sx={{
                borderBottom: location.pathname.includes(paths.aboutUs)
                  ? "2px solid #194a89"
                  : "none",
                color: location.pathname.includes(paths.aboutUs)
                  ? "#194a89"
                  : "inherit",
                "&:hover": {
                  cursor: "pointer",
                  "&::after": {
                    height: "40px",
                    top: "-90%",
                    transition: "height 0.3s ease, top 0.3s ease",
                  },
                  "&::before": {
                    top: "-25%",
                    transition: "top 0.3s ease",
                  },
                },
                position: "relative",
                fontFamily: "inherit",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  height: "30px",
                  width: "2px",
                  backgroundColor: "#194a89",
                  top: "-100%",
                  right: "-30%",
                  transform: "translateX(-50%)",
                },
                "&::before": {
                  content: '""',
                  position: "absolute",
                  height: "7px",
                  width: "7px",
                  backgroundColor: "#194a89",
                  borderRadius: "50%",
                  top: "-55%",
                  right: "-43.5%",
                  transform: "translate(-50%, 20px)",
                },
              }}
              onMouseEnter={() => {
                handleNavHover(); // Close other dropdowns
                setShowAbout(true); // Show About dropdown
              }}
              onMouseLeave={() => setHoveringService(false)}
              variant="body2"
            >
              About
            </Typography>
            <Button
              variant="contained"
              sx={{ background: "#194a89", fontSize: 12 }}
              onMouseEnter={handleNavHover} // Close other dropdowns when hovering over this
              onClick={handleShowContactUs}
            >
              Contact
            </Button>
          </Stack>
        )}
      </Stack>

      <Divider />

      {/* Service Dropdown */}
      <Fade in={showContact || hoveringService} timeout={500}>
        <div
          id="service-menu"
          onMouseEnter={() => setHoveringService(true)}
          onMouseLeave={() => setShowContact(false)}
          style={{
            position: "absolute",
            top: "60px",
            left: 0,
            right: 0,
            zIndex: 10,
            pointerEvents: "auto",
            display: showContact ? "block" : "none",
          }}
        >
          <Stack bgcolor={"white"} sx={{ borderBottom: "1px solid lightgrey" }}>
            {showContact && <Contact />}
          </Stack>
        </div>
      </Fade>

      {/* About Dropdown */}
      <Fade in={showAbout || hoveringAbout} timeout={500}>
        <div
          id="about-menu"
          onMouseEnter={() => setHoveringAbout(true)}
          onMouseLeave={() => setShowAbout(false)}
          style={{
            position: "absolute",
            top: "60px",
            left: 0,
            right: 0,
            zIndex: 10,
            pointerEvents: "auto",
            display: showAbout ? "block" : "none",
          }}
        >
          <Stack bgcolor={"white"} sx={{ borderBottom: '1px solid lightgrey' }}>
            {showAbout && <AboutCards />}
          </Stack>
        </div>
      </Fade>
    </>
  );
};

export { NavBar };
