import { Box, Typography, Stack, Grid } from "@mui/material";
import { GetQuote } from "./getQuote";
import { ProjectCard } from "../components/projectCard";
import { Constants } from "../Comman/Constants";

const projects = [
  {
    title: "Online Foods Cube",
    image: "/images/cabme.jpg", // Replace with actual image path
    description: "Design for Food Ordering App",
    tags: ["UI-UX", "Mobile"],
  },
  {
    title: "Cab Me Ride",
    image: "/images/eatFirst.jpg", // Replace with actual image path
    description: "Leading taxi and transfer service",
    tags: ["UI-UX", "Mobile"],
  },
  {
    title: "Car Parts",
    image: "/images/Theautopartsshop.jpg", // Replace with actual image path
    description: "Auto parts shopping app",
    tags: ["UI-UX", "Mobile"],
  },
  {
    title: "AI Privacy",
    image: "/images/Ai-Privacy.jpg",
    description: "AI privacy and security solution",
    tags: ["UI-UX", "Desktop"],
  },
  {
    title: "Apex Auto Parts ",
    image: "/images/apexautoparts.jpg",
    description: "E-commerce auto parts solutions",
    tags: ["UI-UX", "Desktop"],
  },
  {
    title: "The Auto Parts Shop  ",
    image: "/images/taps.jpg",
    description: "E-commerce auto parts solutions",
    tags: ["UI-UX", "Desktop"],
  },
];

const Work = () => {
  return (
    <>
      <Box
        sx={{
          alignItems: "start",
          flexWrap: "wrap",
        }}
      >
        <Stack ml={{ md: 10, xs: 2 }} mt={{ md: 3, xs: 0 }}>
          <Typography
            fontSize={{ md: 30, xs: 30 }}
            color="#0a2c58"
            fontWeight={700}
            fontFamily= {Constants.fontFamilyJosefinSans}
          >
            WORK
          </Typography>
          <Box
              display="flex"
              alignItems="center"
              justifyContent="start"
              gap={1}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontFamily: Constants.fontFamilyJosefinSans,
                  color: "#333",
                }}
              >
                Home
              </Typography>
              <Box
                sx={{
                  height: "5px",
                  width: "5px",
                  borderRadius: "50%",
                  backgroundColor: "grey",
                }}
              />

              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  color: "gray",
                  fontFamily: Constants.fontFamilyJosefinSans,
                }}
              >
                Work
              </Typography>
            </Box>
        </Stack>
        <Stack
          // spacing={{md:4,}}
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Stack
            width={{ md: "45%", xs: "90%" }}
            justifyContent={"center"}
            alignItems={"center"}
            ml={2}
          >
            <Typography
              fontSize={{ md: 50, xs: 30 }}
              sx={{
                color: "#333",
                fontWeight: "bold",
              }}
              mt={{ xs: 5, md: 0 }}
              fontFamily= {Constants.fontFamilyJosefinSans}
            >
              We Developed Experiences For More Than 50+ Projects{" "}
            </Typography>
          </Stack>
          <Stack width={{ md: "40%", xs: "80%" }}>
            <object
              data="/images/work-animatted-banner.svg"
              type="image/svg+xml"
              width="100%"
              height="auto"
            >
              Your browser does not support SVG.
            </object>
          </Stack>
        </Stack>
      </Box>
      <Stack my={5}>
        <Grid container spacing={2} padding={2}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
              <ProjectCard
                title={project.title}
                image={project.image}
                description={project.description}
                tags={project.tags}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>

      <GetQuote />
    </>
  );
};
export { Work };
