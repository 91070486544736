import { HashRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Router } from "./routes/index";
import { NavBar } from "./nav/navbar";
import { Footer } from "./modules/footer";
import ScrollToTop from "./routes/scroll-top";

export default function App() {
  return (
    <HelmetProvider>
      <HashRouter>
        <ScrollToTop />
        <NavBar />
        <Router />
        <Footer />
      </HashRouter>
    </HelmetProvider>
  );
}