import {
  Button,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography
} from "@mui/material";
import { motion } from "framer-motion";
import SendIcon from "@mui/icons-material/Send";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import emailjs from "@emailjs/browser";
import { Constants } from "../Comman/Constants";

const GetQuote = () => {
  const [showInput, setShowInput] = useState(false);
  const [phoneNum, setPhoneNumber] = useState("");
  const [error, setError] = useState(false);

  const handleSendClick = (e) => {
    if (/^\d{10}$/.test(phoneNum)) {
      setError(false);
      console.log("Phone number submitted:", phoneNum);
      sendEmailGetQuote(e);
    } else {
      setError(true);
    }
  };

  const sendEmailGetQuote = (e) => {
    e.preventDefault();
    var templateParams = {
      phoneNumber: phoneNum
    };

    emailjs.send('service_7p7lbxu', 'template_f9lr5pe', templateParams, 'xbmH34_6os39jU_xh')
      .then(
        (result) => {
          alert("Email sent successfully!");
          setPhoneNumber("");
          setShowInput(false);
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send email. Please try again.");
        }
      );
  };

  return (
    <Stack
      component={motion.div}
      initial={{
        background: "linear-gradient(90deg, #F08080 20%, #6495ED 100%)",
      }}
      animate={{
        background: [
          "linear-gradient(90deg, #F08080 20%, #6495ED 100%)",
          "linear-gradient(90deg, #6495ED 20%, #F08080 100%)",
        ],
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        repeatType: "reverse",
        ease: "easeInOut",
      }}
      sx={{
        height: 400,
        width: "100%",
      }}
    >
      <Stack
        fontSize={30}
        color={"white"}
        mt={10}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        fontFamily={Constants.fontFamilyJosefinSans}
      >
        Let's make a quantifiable difference
      </Stack>
      <Stack
        fontSize={30}
        color={"white"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
        fontFamily={Constants.fontFamilyJosefinSans}
      >
        for your company
      </Stack>
      <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
        {showInput ? (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ position: "relative", width: "26%", minWidth: '250px' }}
          >
            <TextField
              fontFamily={Constants.fontFamilyJosefinSans}
              label="Enter Phone Number"
              variant="outlined"
              value={phoneNum}
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{
                mt: 5,
                bgcolor: "transparent",
                borderRadius: 1,
                width: "100%",
                borderColor: "white",

                "& .MuiOutlinedInput-root": {
                  bgcolor: "transparent",
                  "&:hover fieldset": {
                    borderColor: "white", // Border on hover
                  },
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "white", // Label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "white",
                },
                color: "white",
              }}
              InputProps={{
                sx: { color: "white" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleSendClick}
                      edge="end"
                      sx={{
                        borderRadius: "1px",
                      }}
                    >
                      <SendIcon
                        sx={{
                          color: "#cfe2ff",
                          fontSize: 40,
                          "&:hover": { color: "#0a2c58" },
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              onClick={() => setShowInput(false)}
              sx={{
                position: "absolute",
                top: 5,
                right: -40,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </motion.div>
        ) : (
          <Button
            variant="contained"
            onClick={() => setShowInput(true)}
            sx={{
              mt: 5,
              bgcolor: "white",
              color: "black",
              py: 2,
              width: { md: "25%", xs: "35%" },
              minWidth: "220px",
              "&:hover": {
                bgcolor: "#0a2c58",
                color: "white",
              },
            }}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            Get a Quote
          </Button>
        )}
        {error && (
          <Typography sx={{ color: "red", mt: 1, fontSize: 15 }} fontFamily={Constants.fontFamilyJosefinSans}>
            {"Please Enter a 10 digit valid phone number"}
          </Typography>
        )}{" "}
      </Stack>
    </Stack>
  );
};

export { GetQuote };
