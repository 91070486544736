import { Typography, Stack, Box, Grid } from "@mui/material";
import { GetQuote } from "./getQuote";
import { TeamCard } from "../components/teamCard";
import { Constants } from "../Comman/Constants";

const teams = [
  {
    title: "Lavish Sachdeva",
    image: "/images/LavishSachdeva.png", // Replace with actual image path
    description: "CEO & Founder",
    linkedIn: "https://www.linkedin.com/in/lavish-sachdeva-b98262120/",
    // tags: ["UI-UX", "Mobile"],
  },
  {
    title: "Priyanka Bajaj",
    image: "/images/Priyanka.png", // Replace with actual image path
    description: "Senior Software Engineer (Frontend Developer)",
    linkedIn: "",
    // tags: ["UI-UX", "Mobile"],
  },
  {
    title: "Harshika",
    image: "/images/Harshika.png", // Replace with actual image path
    description: "Senior Software Engineer (Frontend Developer)",
    linkedIn: "",
    // tags: ["UI-UX", "Mobile"],
  },
];

const Team = () => {
  return (
    <>
      <Box
        sx={{
          alignItems: "start",
          flexWrap: "wrap",
        }}
      >
        <Stack ml={{ md: 10, xs: 2 }} mt={{ md: 3, xs: 1 }} alignItems={{md:'start', xs:'center'}}>
          <Typography
            fontSize={{ md: 30, xs: 25 }}
            color="#194a89"
            fontWeight={700}
            fontFamily={Constants.fontFamilyJosefinSans}
          >
            TEAM
          </Typography>
          <Stack direction={"row"} flexWrap={"wrap"}>
          <Box
              display="flex"
              alignItems="center"
              justifyContent="start"
              gap={1}
            >
              <Typography
                sx={{
                    fontSize:{md:15, xs:12},
                  fontWeight: 500,
                  fontFamily: Constants.fontFamilyJosefinSans,
                  color: "#333",
                }}
              >
                Home
              </Typography>

              <Box
                sx={{
                  height: "5px",
                  width: "5px",
                  borderRadius: "50%",
                  backgroundColor: "grey",
                }}
              />

              <Typography
                sx={{
                    fontSize:{md:15, xs:12},
                  fontWeight: 500,
                  color: "#333",
                  fontFamily: Constants.fontFamilyJosefinSans,
                }}
              >
                About
              </Typography>
              <Box
                sx={{
                  height: "5px",
                  width: "5px",
                  borderRadius: "50%",
                  backgroundColor: "grey",
                }}
              />

              <Typography
                sx={{
                    fontSize:{md:15, xs:12},
                  fontWeight: 500,
                  color: "gray",
                  fontFamily: Constants.fontFamilyJosefinSans,
                }}
              >
                Team
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack
          // spacing={{md:4,}}
          direction={"row"}
          flexWrap={"wrap"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Stack
            width={{ md: "45%", xs: "90%" }}
            justifyContent={"center"}
            alignItems={"center"}
            ml={2}
          >
            <Typography
              fontSize={{ md: 40, xs: 30 }}
              sx={{
                color: "#333",
                fontWeight: "bold",
              }}
              mt={{ xs: 5, md: 0 }}
              fontFamily={Constants.fontFamilyJosefinSans}
            >
              At CodeHawx, we embody a fusion of observers, scientists,
              researchers, technicians, designers, and artists, converging into
              a singular entity. Experience Designers and Developers working in
              harmonious collaboration.
            </Typography>
          </Stack>
          <Stack
            width={{ md: "40%", xs: "100%" }}
            mr={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src="/images/team.svg"
              width="100%"
              height="auto"
              alt="Teachnology-Web-Design.png"
            />
          </Stack>
        </Stack>
      </Box>

      <Stack mt={10} mb={5}>
        <Stack
          fontSize={{ md: 50, xs: 30 }}
          color={"#194a89"}
          fontWeight={600}
          alignItems={"center"}
          fontFamily={Constants.fontFamilyJosefinSans}
        >
          Our Members
        </Stack>
        <Stack
          fontSize={{ md: 35, xs: 20 }}
          color={"black"}
          alignItems={"center"}
          mt={3}
          fontFamily={Constants.fontFamilyJosefinSans}
          textAlign={"center"}
        >
          We have faith in we. You'll like your job here.
        </Stack>
        <Stack my={5} mx={4}>
          <Grid container spacing={6} padding={2}>
            {teams.map((team, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={3}
                key={index}
                container
                spacing={2}
              >
                <TeamCard
                  title={team.title}
                  image={team.image}
                  description={team.description}
                  linkedIn={team.linkedIn}
                  tags={team.tags}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>

      <GetQuote />
    </>
  );
};
export { Team };
