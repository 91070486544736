import { useRoutes } from "react-router-dom";
import { Home } from "../modules/home";
import { AboutUs } from "../modules/aboutUs";
import { Team } from "../modules/team";
import { Clients } from "../modules/clients";
import { ContactUs } from "../modules/contactUs";
import Design from "../modules/design";
import Technology from "../modules/technology";
import WebDesign from "../modules/servicesPages/webDesign";
import { Work } from "../modules/work";
import { Blog } from "../modules/blog";
import { paths } from "../routes/paths";
import { WebDevelopment } from "../modules/servicesPages/webDevelopment";
import { BackEnd } from "../modules/servicesPages/backEnd";
import { DotNet } from "../modules/servicesPages/dotNet";
import { ReactDevelopment } from "../modules/servicesPages/reactDevelopment";
import { UiDesign } from "../modules/servicesPages/ui-design";
import { UiDevelopment } from "../modules/servicesPages/ui-development";
import { UiGuidelines } from "../modules/servicesPages/ui-guidelines";
import { DesignSystem } from "../modules/servicesPages/design-system";
import { UxWriting } from "../modules/servicesPages/uxWriting";

export function Router() {
  return useRoutes([
    {
      children: [
        { path: paths.home, element: <Home /> },
        { path: paths.work, element: <Work /> },
        { path: paths.clients, element: <Clients /> },
        { path: paths.aboutUs, element: <AboutUs /> },
        { path: paths.team, element: <Team /> },
        { path: paths.contactUs, element: <ContactUs /> },
        { path: paths.design, element: <Design /> },
        { path: paths.technology, element: <Technology /> },
        { path: paths.webDesign, element: <WebDesign /> },
        { path: paths.blog, element: <Blog /> },
        { path: paths.webDevelopment, element: <WebDevelopment /> },
        { path: paths.backEnd, element: <BackEnd /> },
        { path: paths.dotNet, element: <DotNet /> },
        { path: paths.ReactDevelopment, element: <ReactDevelopment /> },
        { path: paths.UiDevelopment, element: <UiDevelopment /> },
        { path: paths.UiGuidelines, element: <UiGuidelines /> },
        { path: paths.designSystem, element: <DesignSystem /> },
        { path: paths.uxWriting, element: <UxWriting /> },
        { path: paths.UiDesign, element: <UiDesign /> },
      ],
    },
  ]);
}
